import router from "@/router";
export default {
  name: "403",
  methods: {
    handlerRoute() {
      if (window.$wujie) {
        window.$wujie.props.jump('/home');
      } else {
        router.replace('/');
      }
    }
  }
};